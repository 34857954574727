/*** スクロールバー reset.scss ***/

html {
  scrollbar-color: lighten($ui-base-color, 22%) lighten($ui-base-color, 8%);
}

::-webkit-scrollbar-thumb {
  background: rgba(lighten($ui-base-color, 22%), 0.3);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(lighten($ui-base-color, 26%), 0.3);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(lighten($ui-base-color, 22%), 0.3);
}

::-webkit-scrollbar-track {
  background: rgba(lighten($ui-base-color, 8%), 0.3);
}

::-webkit-scrollbar-track:hover {
  background: rgba(lighten($ui-base-color, 12%), 0.3);
}

::-webkit-scrollbar-track:active {
  background: rgba(lighten($ui-base-color, 8%), 0.3);
}

/*** 背景全体 basic.scss ***/

body {
  &.app-body {
    background: url($wallpaper) no-repeat fixed center top;
  }

  &.about-body {
    background: url($wallpaper) no-repeat fixed center top;
  }

  &.tag-body {
    background: url($wallpaper) no-repeat fixed center top;
  }

  &.admin {
    background: url($wallpaper) no-repeat fixed center top;
  }

  &.error {
    background: url($wallpaper) no-repeat fixed center top;
  }

/*** プロフィールページ用 (https://ドメイン/@アカウント) ***/

  &.no-reduce-motion {
    background: url($wallpaper) no-repeat fixed center top;
  }
}

/*** statuses.scss ***/

.activity-stream {
  .entry {
    background: rgba($simple-background-color, 0.3);
  }
}

/*** widgets.scss ***/

.hero-widget {
  &__img {
    background: rgba($base-shadow-color, 0.3);
  }

  &__text {
    background: rgba($ui-base-color, 0.3);
  }
}

.moved-account-widget {
  background: rgba($ui-base-color, 0.3);
}

.memoriam-widget {
  background: rgba($ui-base-color, 0.3);
}

.directory {
  background: rgba($ui-base-color, 0.3);

  &__tag {
    & > a,
    & > div {
      background: rgba($ui-base-color, 0.3);
    }
  }
}

/*** components.scss 1332行目 ***/

.focusable {
  &:focus {
    background: rgba(lighten($ui-base-color, 4%), 0.3);

    .detailed-status,
    .detailed-status__action-bar {
      background: rgba(lighten($ui-base-color, 8%), 0.3);
    }
  }
}

/*** components.scss 1618行目 ***/

.detailed-status {
  background: rgba(lighten($ui-base-color, 4%), 0.3);
}

/*** components.scss 1680行目 ***/

.detailed-status__action-bar {
  background: rgba(lighten($ui-base-color, 4%), 0.3);
}

/*** components.scss 2552行目 ***/

.column {
  > .scrollable {
    background: rgba($ui-base-color, 0.3);
  }
}

/*** UIの背景全体 components.scss 2552行目 ***/

.ui {
  background: transparent;
}

/*** トゥート作成・検索欄 components.scss 3182行目 ***/

.drawer__inner {
  background: rgba(lighten($ui-base-color, 13%), 0.3);
}

.drawer__inner__mastodon {
  background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234.80078 31.757813" width="234.80078" height="31.757812"><path d="M19.599609 0c-1.05 0-2.10039.375-2.90039 1.125L0 16.925781v14.832031h234.80078V17.025391l-16.5-15.900391c-1.6-1.5-4.20078-1.5-5.80078 0l-13.80078 13.099609c-1.6 1.5-4.19883 1.5-5.79883 0L179.09961 1.125c-1.6-1.5-4.19883-1.5-5.79883 0L159.5 14.224609c-1.6 1.5-4.20078 1.5-5.80078 0L139.90039 1.125c-1.6-1.5-4.20078-1.5-5.80078 0l-13.79883 13.099609c-1.6 1.5-4.20078 1.5-5.80078 0L100.69922 1.125c-1.600001-1.5-4.198829-1.5-5.798829 0l-13.59961 13.099609c-1.6 1.5-4.200781 1.5-5.800781 0L61.699219 1.125c-1.6-1.5-4.198828-1.5-5.798828 0L42.099609 14.224609c-1.6 1.5-4.198828 1.5-5.798828 0L22.5 1.125C21.7.375 20.649609 0 19.599609 0z" fill="#{hex-color(lighten($ui-base-color, 20%))}"/></svg>') no-repeat bottom / 100% auto;
}

.drawer__header {
  background: rgba(lighten($ui-base-color, 8%), 0.3);
}

/*** components.scss 3279行目 ***/

.column-back-button {
  background: transparent;
}

.column-header__back-button {
  background: transparent;
}

/*** components.scss 3416行目 ***/

.column-link {
  background: rgba(lighten($ui-base-color, 12%), 0.3);
  &:hover {
    background: rgba(lighten($ui-base-color, 22%), 0.6);
  }
}

.column-link__badge {
  background: rgba($ui-base-color, 0.3);
}

.column-subheading {
  background: rgba($ui-base-color, 0.3);
}

.getting-started__wrapper,
.getting-started,
.flex-spacer {
  background: rgba($ui-base-color, 0.15);
}

/*** components.scss 3926行目 ***/

.regeneration-indicator {
  background: rgba($ui-base-color, 0.3);
}

/*** components.scss 4004行目 ***/

.column-header {
  background: rgba(lighten($ui-base-color, 4%), 0.3);
}

/*** components.scss 4068行目 ***/

.column-header__button {
  background: transparent;

  &.active {
    background: rgba(lighten($ui-highlight-color, 8%), 0.6);

    &:hover {
      background: rgba(lighten($ui-highlight-color, 14%), 0.6);
    }
  }
}

/*** components.scss 4137行目 ***/

.column-header__collapsible-inner {
  background: rgba(lighten($ui-base-color, 8%), 0.6);
}

/*** components.scss 4602行目 ***/

.empty-column-indicator,
.follow_requests-unlocked_explanation {
  background: rgba($ui-base-color, 0.3)
}

/*** components.scss 4637行目 ***/

.error-column {
  background: rgba($ui-base-color, 0.3)
}

/*** components.scss 6922行目 ***/

.notification__filter-bar,
.account__section-headline {
  background: rgba(darken($ui-base-color, 4%), 0.5);

  button {
    background: rgba(darken($ui-base-color, 4%), 0.3);
    }

  button,
  a {
    color: rgba($white, 0.7);

    &.active {
      color: $success-green;

      &::after {
        border-color: transparent transparent lighten($ui-base-color, 8%);
      }
    }
  }
}

/*** forms.scss 166行目 ***/

.simple_form {
  .hint {
    code {
      background: rgba(darken($ui-base-color, 12%), 0.7);
    }
  }

/*** forms.scss 423行目 ***/

  input[type=text],
  input[type=number],
  input[type=email],
  input[type=password],
  textarea {
    background: rgba(darken($ui-base-color, 10%), 0.7);
  }

/*** forms.scss 519行目 ***/

  button,
  .button,
  .block-button {
    background: rgba($ui-highlight-color, 0.7);

    &:hover {
      background-color: rgba(lighten($ui-highlight-color, 5%), 0.7);
    }

    &:active,
    &:focus {
      background-color: rgba(darken($ui-highlight-color, 5%), 0.7);
    }

    &.negative {
      background: rgba($error-value-color, 0.7);

      &:hover {
        background-color: rgba(lighten($error-value-color, 5%), 0.7);
      }

      &:active,
      &:focus {
        background-color: rgba(darken($error-value-color, 5%), 0.7);
        background-color: darken($error-value-color, 5%);
      }
    }
  }

/*** forms.scss 576行目 ***/

  select {
    background: rgba(darken($ui-base-color, 10%), 0.7) url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.933 18.467' height='19.698' width='15.929'><path d='M3.467 14.967l-3.393-3.5H14.86l-3.392 3.5c-1.866 1.925-3.666 3.5-4 3.5-.335 0-2.135-1.575-4-3.5zm.266-11.234L7.467 0 11.2 3.733l3.733 3.734H0l3.733-3.734z' fill='#{hex-color(lighten($ui-base-color, 12%))}'/></svg>") no-repeat right 8px center / auto 16px;
  }
}

/*** forms.scss 1005行目 ***/

.input-copy {
  background: rgba(darken($ui-base-color, 10%), 0.7);
}

/*** tables.scss ***/

.table {
  th,
  td {
  background: rgba(darken($ui-base-color, 4%), 0.7);
  }
}

/*** accounts.scss ***/

.card {
  & > a {
    &:hover,
    &:active,
    &:focus {
      .card__bar {
        background: rgba(lighten($ui-base-color, 8%), 0.7);
      }
    }
  }
}

/*** dashboard.scss ***/

.dashboard__counters {
  & > div {
    & > div,
    & > a {
      background: rgba(lighten($ui-base-color, 6%), 0.7);
    }

    & > a {
      &:active {
        background: rgba(lighten($ui-base-color, 10%), 0.7);
      }
    }
  }
}

/*** aboutページ mastodon-light/diff.scss  ***/

.about__meta,
.about__section__title,
.interaction-modal {
  background: rgba(darken($ui-base-color, 4%), 0.7);
}

/*** admin.scss  ***/

.admin-wrapper {
  .sidebar-wrapper {
    &__inner {
      background: transparent;
    }
  }

  .sidebar {
    ul {
      a {
        &.selected {
          background: rgba($ui-base-color, 0.4);
        }
      }

      ul {
        background: rgba(darken($ui-base-color, 4%), 0.6);
      }
    }
  }

  .content-wrapper {
    background: rgba($ui-base-color, 0.2);
  }
}
